export default class Constants {
  //temporary for development
  //public static readonly TOKEN =
  //"eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJlc0hsdDNCc2h5ZFhqbkF6SGc5UUcxZlZBMWMyZGxid2gzZmRrcjNVMUJVIn0.eyJleHAiOjE2NzQ4NTMxMDIsImlhdCI6MTY3NDgyNDMwNSwiYXV0aF90aW1lIjoxNjc0ODI0MzA0LCJqdGkiOiI2ZjFmMzYxZS02NTk5LTRlYWItOTU0NS1lNWYwNjFjZWFlNTYiLCJpc3MiOiJodHRwczovL2F1dGh0ZXN0LnNpZ25hbGFwcC5uZXQvYXV0aC9yZWFsbXMvU2lnbmFsIiwiYXVkIjoiU2lnbmFsIiwic3ViIjoiMTMyM2E3NzYtYjNmNS00YWZkLWJiNmUtNTA0NWNjZjA0ZDRiIiwidHlwIjoiSUQiLCJhenAiOiJTaWduYWwiLCJub25jZSI6ImZhNzJhZTZjLTgzNzYtNDk1NS1hOTAzLTBlZjY3MWE4OGFiZCIsInNlc3Npb25fc3RhdGUiOiI3NTQ2YmFmZC1jMGI4LTQyODktYmY3OC1lMGQwNDM0OTFhMGMiLCJhdF9oYXNoIjoiU0dLUWQ1bkRCSkdCS2kyTjkzdFBNZyIsImFjciI6IjEiLCJzaWduYWxfdXNlcl9pZCI6IjU5MzA3ZGI1LTZjOWMtNDE0MC1hNWFjLTA4OWRiNjA5OTk0OSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiQnJlbmRvbiBDbyIsInByZWZlcnJlZF91c2VybmFtZSI6ImJyZW5kb25jb0BnbWFpbC5jb20iLCJnaXZlbl9uYW1lIjoiQnJlbmRvbiIsImZhbWlseV9uYW1lIjoiQ28iLCJlbWFpbCI6ImJyZW5kb25jb0BnbWFpbC5jb20ifQ.pZF5UFnvqDY7E5QNdBI-pCEgQtdWTudEBXuhml7W7SDVOxt1lXfmUgYfNdOc4KS3hwUaFjgn5WJay5gWinQi_J5cTdBmoSCo9ghFtqllNDOfZ1vm1kVttk_-oye5pNYEClshuQV3yLz6ZRpKbf0ijP4vXC2moPuKLkvbleGsQvYns2af84SNh4Zr3drD0KpC7KkCPr_a_HeKsRRDOa01W9T1XSX_F5TzFTwSMHcVLcnvxApz4mQVh-SrW9YrOwtqSahHIfltMMeN60Hxe582XoVFQjbksx84xNzNA5fsy5HBaSk_KNZ9D45h1GfgAhmBDs45QhD2TmQBJfFF2W3U0Q";
  //public static readonly API_URL = "https://dev.signalapp.net/api";
  //public static readonly KEYCLOAK_URL = "https://authtest.signalapp.net";
  public static readonly DATETIME_FORMAT = "MM/dd/yyy HH:mm:ss";
  public static readonly DATE_FORMAT = "MM/dd/yyy";
  public static readonly GRID_PAGE_SIZE = 50;
  public static readonly DEFAULT_SESSION_LENGTH = 8; // In Hours
  public static readonly ROLE_POWER_ANALYST = "PowerAnalyst";
  public static readonly ROLE_READ_ONLY = "Readonly";
  public static readonly LIVESTREAM_SEARCH_DISPLAY_LIMIT = 48;
}

export const RodrikModels = {
  DeathThreat: "death_threat",
  HateSpeech: "hate_speech",
  Shooting: "shooting",
  Terrorism: "terrorism",
  Earthquake: "earthquake",
  NaturalDisaster: "natural_disaster",
  ReputationalRisk: "reputational_risk",
  Conflict: "conflict",
  RoadDisruption: "road_disruption",
  Fire: "fire",
  PowerOutage: "power_outage",
  Landslide: "landslide",
  Blizzard: "blizzard",
  Flood: "flood",
  StructureCollapse: "structure_collapse",
  Tsunami: "tsunami",
  FlightDisruption: "flight_disruption",
  Wind: "wind",
  BombThreat: "bomb_threat",
  ChemicalSpill: "chemical_spill",
  Outbreak: "outbreak",
};

export const RodrikModelLookup = [
  { id: "death_threat", name: "Death Threat" },
  { id: "hate_speech", name: "Hate Speech" },
  { id: "shooting", name: "Shooting" },
  { id: "terrorism", name: "Terrorism" },
  { id: "earthquake", name: "Earthquake" },
  { id: "natural_disaster", name: "Natural Disaster" },
  { id: "reputational_risk", name: "Reputational Risk" },
  { id: "conflict", name: "Conflict" },
  { id: "road_disruption", name: "Road Disruption" },
  { id: "fire", name: "Fire" },
  { id: "power_outage", name: "Power Outage" },
  { id: "landslide", name: "Landslide" },
  { id: "blizzard", name: "Blizzard" },
  { id: "flood", name: "Flood" },
  { id: "structure_collapse", name: "Structure Collapse" },
  { id: "tsunami", name: "Tsunami" },
  { id: "flight_disruption", name: "Flight Disruption" },
  { id: "wind", name: "Wind" },
  { id: "bomb_threat", name: "Bomb Threat" },
  { id: "chemical_spill", name: "Chemical Spill" },
  { id: "outbreak", name: "Outbreak" },
];

export const RodrikIconLookup = [
  { id: "death_threat", icon: "fas fa-skull" },
  { id: "hate_speech", icon: "fas fa-angry" },
  { id: "shooting", icon: "fas fa-gun" },
  { id: "terrorism", icon: "fas fa-person-rifle" },
  { id: "earthquake", icon: "fas fa-house-damage" },
  { id: "natural_disaster", icon: "fas fa-volcano" },
  { id: "reputational_risk", icon: "fas fa-star-exclamation" },
  { id: "conflict", icon: "fas fa-person-military-rifle" },
  { id: "road_disruption", icon: "fas fa-traffic-cone" },
  { id: "fire", icon: "fas fa-fire" },
  { id: "power_outage", icon: "fas fa-plug-circle-xmark" },
  { id: "landslide", icon: "fas fa-hill-rockslide" },
  { id: "blizzard", icon: "fas fa-snow-blowing" },
  { id: "flood", icon: "fas fa-house-flood-water" },
  { id: "structure_collapse", icon: "fas fa-building-circle-exclamation" },
  { id: "tsunami", icon: "fas fa-wave" },
  { id: "flight_disruption", icon: "fas fa-plane-circle-exclamation" },
  { id: "wind", icon: "fas fa-wind" },
  { id: "bomb_threat", icon: "fas fa-bomb" },
  { id: "chemical_spill", icon: "fas fa-biohazard" },
  { id: "outbreak", icon: "fas fa-face-mask" },
];
