import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
} from "@chakra-ui/react";
import { IEmail } from "@/interfaces/IEmail";
import { sendReport } from "@/services/useBriefer";
import { CustomToast } from "@/common/toast/CustomToast";

const BriefReportModal = ({
  isOpen,
  onClose,
  briefReport,
  isEditContent,
  lang,
  briefer = null,
  sources = null,
}) => {
  const { successToast, errorToast } = CustomToast();
  const [email, setEmail] = useState<IEmail>({
    fromAddress: "",
    toAddress: "",
    subject: "",
    body: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (isEditContent) {
      setEmail((prevState) => ({
        ...prevState,
        subject: briefer?.title,
        body: convertHtmlToPlainText(briefReport)
      }));
    } else {
      setEmail((prevState) => ({
        ...prevState,
        subject: briefer?.title
      }));
    }
  }, [isEditContent, briefReport, briefer]);

  const handleInputChange = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      toAddress: e.target.value,
    }));
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addTag(email?.toAddress.trim());
    }
  };

  const addTag = (tag) => {
    if (tag !== "" && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setEmail((prevState) => ({
        ...prevState,
        toAddress: "",
      }));
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const convertHtmlToPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
  
    // Replace <br> and block-level elements, including <dl> and <dt>, with newline characters
    let text = tempDiv.innerHTML
      .replace(/<br\s*\/?>/gi, "\n")
      .replace(/<\/(p|div|h[1-6]|li|dt|dd|dl)>/gi, "\n");
  
    // Set the processed text as the div's content to strip remaining HTML tags
    tempDiv.innerHTML = text;
  
    // Extract plain text from the div
    text = tempDiv.textContent || tempDiv.innerText || "";
  
    // Remove emojis and special symbols
    text = text.replace(
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]+/gu,
      ""
    );
  
    // Normalize multiple consecutive newlines
    text = text.replace(/\n\s*\n/g, "\n").trim();
  
    return text;
  };

  const handleSendEmail = async () => {
    const tagsToAddress = tags.toString();
    let brieferReportModel = {
      isPost: briefer.isPost ? true: false,
      title: briefer.title,
      location: briefer.locationDescription,
      longitude: briefer.location?.longitude,
      latitude: briefer.location?.latitude,
      createdDate: briefer.createdDate,
      confidenceLevel: briefer?.confidenceLevel,
      toAddress: tagsToAddress,
      subject: email.subject,
      body: isEditContent ? "" : email.body,
      briefReport: isEditContent ? email.body : briefReport,
      sources: [],
    };

    if (sources?.length > 0) {
      const contents = sources.map((x) => {
        const newContent = convertHtmlToPlainText(x.content);
        return newContent;
      });

      brieferReportModel.sources = contents;
    }

    setIsSending(true);
    const isSent = await sendReport(brieferReportModel);
    if (isSent) {
      successToast("Briefer report", "Successfully sent.");
    } else {
      errorToast("Briefer report", "Unable to send report.");
    }
    setIsSending(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {lang?.ADVANCED_POST_BRIEFER_SENDREPORT || "Send report"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>{lang?.TO || "To"}</FormLabel>
            <Input
              type="email"
              value={email.toAddress}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder={
                lang?.ADVANCED_POST_BRIEFER_PLACEHOLDER1 ||
                "Please type email address and press enter"
              }
            />
            {tags.length > 0 && (
              <Box border="1px solid #718096" borderRadius="md" p="1" mt={2}>
                {tags.map((tag) => (
                  <Tag key={tag} size="md" m="1" mt={2}>
                    <TagLabel>{tag}</TagLabel>
                    <TagCloseButton onClick={() => removeTag(tag)} />
                  </Tag>
                ))}
              </Box>
            )}
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>
              {lang?.ADVANCED_POST_BRIEFER_SUBJECT || "Subject"}
            </FormLabel>
            <Input
              type="text"
              value={email.subject}
              onChange={(e) =>
                setEmail((prevState) => ({
                  ...prevState,
                  subject: e.target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              {isEditContent
                ? lang?.ADVANCED_POST_BRIEFER_CONTENT || "Content"
                : "Body"}
            </FormLabel>
            <Textarea
              value={email.body}
              onChange={(e) =>
                setEmail((prevState) => ({
                  ...prevState,
                  body: e.target.value,
                }))
              }
              rows={6}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="messenger"
            mr={3}
            onClick={handleSendEmail}
            isLoading={isSending}
            loadingText={"sending report..."}
            isDisabled={email.subject === "" || tags.length === 0}
          >
            {lang?.SEND || "Send"}
          </Button>
          <Button colorScheme="gray" onClick={onClose}>
            {lang?.CANCEL || "Cancel"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BriefReportModal;
